
.loginForm {
    background-color: white;
    margin-top: 30vh;
    
    padding: 10px;
    opacity: 0.7;
    border-radius: 50px;
}
.cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
   background-image: url("login-bg.jpg");
   
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    opacity: 1;
    -webkit-filter: blur(5px); /* Safari 6.0 - 9.0 */
    filter: blur(5px);
    
}
 /* Inactive/Active Default input field color */

 .input-field input[type]:focus:not([readonly]) {
     border-bottom: 1px solid #ff9800;
     box-shadow: 0 1px 0 0 #ff9800;
 }
 .errorMsg {
     color: red;
     font-family: sans-serif;
     text-transform: capitalize;
     font-weight: bolder;
     text-align: center;
     
 }
 .btn {
     margin: 10px;
 }

 .white {
     border-radius: 50px;
 }