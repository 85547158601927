.blogo{
    font-family: Mogra;
}
.oText{
    color: #ff9800;
    display: inline;
}
.bgColor{
    background-color: #ff9800;
}
.bgColor:hover{
    background-color: #0d47a1;
}