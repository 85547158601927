.headingText{
    font-family: sans-serif;
    font-size: 25px;
    font-weight: bolder;
    display: block;
    text-align: center;
    color: #ff9800;
}
.filterForm{
    display: block;
}
.backArrow{
    color: #ff9800;
    display: block;
    text-align: right;
    margin-right: 200px;
    margin-top: 20px;
}
.backArrow:hover{
    color: #0d47a1;
    display: block;
    text-align: right;
    margin-right: 200px;
    margin-top: 20px;
    cursor: pointer;
    -moz-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.addBookingTR{
    cursor: pointer;
}
.addBookingTR:hover{
    background-color: #ff9800;
}
.addBookingReasonSelect{
    padding-left: 50px;
    padding-top: 10px;
    display: block;

}
.hidden {
    display: none;
}
.addBookingDateSelector{
    padding-left: 50px;
    padding-top: 20px;
    display: block;
}
.addBookingModal{
    width: 95% !important ; height: 100% !important ;
}
.newBookingCheckbox{
    margin-left: 40px;
    padding-top: 10px;
}
.newBookingCheckboxLabel {
    margin-left: 50px;
    padding-top: 10px;
}
.bookingReason{
    border-style: none;
}
.addBookingDateSelector {
    margin-top: -10px;
    top: -200px;
    margin-left: -10px;
}