.mainTitle {
  text-align: center;
  font-size: 20px;
  position: relative;
  top: 230px;
  margin-left: 50px;
  margin-right: 50px;
}
.date{
  font-size: 20px;
  position: absolute;
  left: 108px;
  top: 425px;
}
.ang{
  font-size: 20px;
  position: absolute;
  right: 200px;
  top: 425px;
}
.mainBody{
  text-align: center;
  font-size: 25px;
  position: absolute;
  top: 450px;
  margin-left: 50px;
  margin-right: 50px;
}
.mainBodyLarge{
  text-align: center;
  font-size: 35px;
  position: absolute;
  top: 410px;
  margin-left: 50px;
  margin-right: 50px;
}
.mainBodySmall{
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 420px;
  margin-left: 50px;
  margin-right: 50px;
}

