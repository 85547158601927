.userActionButton{
    margin-right: 20px;
    cursor: pointer;
    color: #ff9800;
}

.userActionButton:hover{
    color: #0d47a1;

}


.btn, .btn-large, .btn-small {
    text-decoration: none;
    color: #fff;
    background-color: #ff9800; 
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
    text-decoration: none;
    color: #fff;
    background-color: #0d47a1; 
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

.modalButtonMargin {
    float:right;
    padding-right: 20px;
}

.btn-red:hover {
    background-color: red;
}
.redHover:hover {
    color: red;
}

.input-field input[type=text]:focus + label {
    color: #ff9800;
}