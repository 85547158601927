.hoverable:hover {
    color: white;
    background-color: #ff9800 ;
    cursor: pointer;
}

.colMax {
    height:94vh;
    overflow-y: scroll;
    margin-top: 0px;
}

.notesSection {
    padding-left: 50px;
}

.table {
    position: absolute;
}

.dashItem{
    display: block;
    border-radius: 50px;
    background-color: #ff9800;
    width: 200px;
    height: 200px;
    color: white;
    text-align: center;
    margin: auto;

}
.dashItemTitle{
    text-align: center;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bolder;
  
}
.dashItemIcon{
    text-align: center;
    display: block;
    padding-top: 30px;
    padding-bottom: 0px;
    margin-bottom: -20px;
    
}

.dashItem:hover {
    background-color: #0d47a1;
    color: white;
    cursor: pointer;
    -moz-transition: all .3s ease-in;
    -o-transition: all .3s ease-in;
    -webkit-transition: all .3s ease-in;
    transition: all .3s ease-in;
}

.dashMenu{
    margin-top: 200px;
}
.spacer{
    width: 20px;
    height: 20px;
}

  .col {
    margin-top: 20px;
}